import React from "react"
import { graphql } from "gatsby"

import TeachingPage from "../components/teachingView"
import SEO from "../components/seo"

const Choral = ({ data }) => {

  const hero = data.mdx.frontmatter.hero.childImageSharp.fluid.src
  const heading = data.mdx.frontmatter.heading
  const buttonText = data.mdx.frontmatter.buttonText
  const buttonLink = data.mdx.frontmatter.buttonLink
  const extraImage = data.mdx.frontmatter.image ? data.mdx.frontmatter.image.childImageSharp.fluid : null
  const imageLink = data.mdx.frontmatter.imageLink

  return (
    <div id="top">
      <SEO
        title="Choral conductor teaching"
        keywords={['conductor education', 'choral conductor training']}
      />


      <TeachingPage hero={hero} title={heading} buttonLink={buttonLink} buttonText={buttonText} extraImage={extraImage} imageLink={imageLink}>
        {data.mdx.body}
      </TeachingPage>
    </div >
  )
}



export default Choral

export const query = graphql`
query Choral {
    mdx(fileAbsolutePath: {regex: "/choral-conducting.md/"}) {
      frontmatter {
        heading
        hero {
          childImageSharp {
            fluid(maxWidth: 2500) {
              src
              ...GatsbyImageSharpFluid
            }
          }
        }
        buttonText
        buttonLink
        image {
          childImageSharp {
            fluid(maxWidth: 2500) {
              src
              ...GatsbyImageSharpFluid
            }
          }
        }
        imageLink
      }
      body
    }
    imio: file(name: {eq: "imio-logo-trasp"}) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`